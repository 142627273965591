<template>
  <Page id="terms-and-conditions" class="terms-and-conditions-page">
    <template #header>
      <SecondaryPageBackButton />
    </template>
    <SecondaryPageBody
      :markdown-data="footerTexts.terms_and_conditions_body"
      :is-scrolled="true"
      template="footer"
      field="terms_and_conditions_body"
    />
  </Page>
</template>

<script lang="ts">
import {
  Page,
  provideIsSecondaryPage,
  SecondaryPageBackButton,
  SecondaryPageBody,
  useCommonGeneralStore,
} from "@cna/common";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "TermsAndConditionsPage",
  components: {
    SecondaryPageBackButton,
    SecondaryPageBody,
    Page,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const footerTexts = computed(() => commonGeneral.footerTexts);

    provideIsSecondaryPage(ref(true));

    return {
      footerTexts,
    };
  },
});
</script>
