import { useWatchLangQuery } from "@/hooks/useWatchLangQuery";
import { metaKeyOrEditor } from "@/routes/router";
import saveGeneralDataToCommonStore from "@/utils/commonLibUtils";
import {
  setCssVariablesForImages,
  setCssVariablesForStyles,
  useCommonImagesStore,
  useCommonStylesStore,
  resetStyles,
  useRouteQuery,
} from "@cna/common";
import { computed, defineComponent, h, type Ref, toRefs, watch } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import { setCssVariablesForEditorSettings } from "../utils/editorSettingsUtils";
import { useEditorStore } from "@/stores/editor";
import { storeToRefs } from "pinia";
import { useFullWidthSecondaryPageLayout } from "../hooks/useFullWidthSecondaryPageLayout";

export const WithProjectId = defineComponent({
  name: "with-project-id",
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const { projectId } = toRefs(props);

    const commonImages = useCommonImagesStore();
    const commonStyles = useCommonStylesStore();
    const editorStore = useEditorStore();
    const { versions, isDBPortalType } = storeToRefs(editorStore);
    useWatchLangQuery();
    saveGeneralDataToCommonStore();

    const versionID = useRouteQuery("version_id", {
      type: String,
      default: "",
    }) as Ref<string>;

    const isPreview = computed(
      () => route.path === `/${projectId.value}/preview`
    );

    const { handleNewLayoutNotification } = useFullWidthSecondaryPageLayout(
      isPreview,
      isDBPortalType
    );

    const wrongVersionId = computed(
      () =>
        !versions.value.find(
          (version) => version.id === Number(versionID.value)
        ) && versionID.value !== undefined
    );

    const initSettings = async (projectId: number) => {
      editorStore.updateCurrentProjectId(projectId);
      if (isPreview.value) {
        await handlePreview();
      } else {
        await editorStore.initProjectData();
        handleNewLayoutNotification();
      }
    };

    const handlePreview = async () => {
      await editorStore.fetchVersions();
      if (wrongVersionId.value) {
        await router.push({ name: "NotFound" });
      } else {
        editorStore.initProjectData(versionID.value);
      }
    };

    watch(
      [
        () => commonStyles,
        () => commonImages,
        () => editorStore.editorSettings,
      ],
      () => {
        resetStyles();
        setCssVariablesForStyles(commonStyles.mainBlockStyles);
        setCssVariablesForStyles(commonStyles.footerStyles);
        setCssVariablesForStyles(commonStyles.headerStyles);
        setCssVariablesForStyles(commonStyles.defaultFonts);
        setCssVariablesForImages(commonImages.bodyBackgroundStyles);
        setCssVariablesForEditorSettings(editorStore.editorSettings ?? {});
      },
      { deep: true }
    );

    watch(
      projectId,
      (val) => {
        initSettings(+val);

        router.push({
          path: `/${val}/${metaKeyOrEditor(route)}`,
          query: { ...route.query },
        });
      },
      { immediate: true }
    );

    return () => h(RouterView);
  },
});
