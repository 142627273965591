<template>
  <Page id="contact-us" class="contact-us-page">
    <template #header>
      <SecondaryPageBackButton />
    </template>
    <SecondaryPageBody
      :markdown-data="footerTexts.contact_us_body"
      template="footer"
      field="contact_us_body"
    />
  </Page>
</template>

<script lang="ts">
import {
  Page,
  provideIsSecondaryPage,
  SecondaryPageBackButton,
  SecondaryPageBody,
  useCommonGeneralStore,
} from "@cna/common";
import { storeToRefs } from "pinia";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ContactUsPage",
  components: { SecondaryPageBody, SecondaryPageBackButton, Page },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const { footerTexts, features } = storeToRefs(commonGeneral);

    provideIsSecondaryPage(ref(true));

    return {
      footerTexts,
      features,
    };
  },
});
</script>
