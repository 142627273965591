import { computed, type Ref } from "vue";
import { useCommonGeneralStore } from "@cna/common";
import { useNotification } from "./use-notification";
import { useEditorStore } from "@/stores/editor";
import { api } from "@/api";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

const toggleFullWidthSecondaryPageFeature = (value: boolean) => {
  const editorStore = useEditorStore();
  const projectId = editorStore.currentProjectId;
  const headerPaddingOldTemplate = "14px 15px 12px 15px";
  const headerPaddingNewTemplate = "8px 20px 8px 20px";
  const footerPaddingOldTemplate = "15px 15px 14px 15px";
  const footerPaddingNewTemplate = "10px 14px 15px 14px";

  if (!projectId) return;

  let contentWidth = "64rem";

  if (value) {
    contentWidth = "100%";
  }

  api.v1.projects
    .project(projectId)
    .postFeatures({ full_width_secondary_page: value });

  editorStore
    .updateTemplateStyles({ header_content_width: contentWidth }, "header")
    .then(() => editorStore.fetchStyles());

  editorStore
    .updateTemplateStyles({ footer_content_width: contentWidth }, "footer")
    .then(() => editorStore.fetchStyles());

  editorStore
    .updateTemplateStyles(
      {
        header_padding: value
          ? headerPaddingNewTemplate
          : headerPaddingOldTemplate,
      },
      "header"
    )
    .then(() => editorStore.fetchStyles());

  editorStore
    .updateTemplateStyles(
      {
        footer_padding: value
          ? footerPaddingNewTemplate
          : footerPaddingOldTemplate,
      },
      "footer"
    )
    .then(() => editorStore.fetchStyles());
};

export const useFullWidthSecondaryPageLayout = (
  isPreview: Ref<boolean>,
  isDBPortalType: Ref<boolean>
) => {
  const commonGeneral = useCommonGeneralStore();
  const notification = useNotification();
  const { features } = storeToRefs(commonGeneral);
  const route = useRoute();

  const fullWidthFeatureCheck = computed(
    () => features.value.full_width_secondary_page === undefined
  );

  const offerNewLayout = () => {
    notification.confirm({
      title: "New layout available",
      description:
        "Check our improved secondary page layout - try it out today!",
      confirmButtonTitle: "Try now",
      closeButtonTitle: "Close",
      onConfirm: () => {
        toggleFullWidthSecondaryPageFeature(true);
        localStorage.setItem("newLayoutConfirmed", "true");
        window.location.reload();
      },
      onCancel: () => {
        toggleFullWidthSecondaryPageFeature(false);
        notification.destroyAll();
      },
    });
  };

  const confirmNewLayout = () => {
    notification.confirm({
      title: "Confirm your layout choice",
      description:
        "Do you want to keep the new layout or revert to the old one?",
      confirmButtonTitle: "Keep & save",
      closeButtonTitle: "Revert",
      onConfirm: () => {
        localStorage.removeItem("newLayoutConfirmed");
        notification.destroyAll();
      },
      onCancel: () => {
        toggleFullWidthSecondaryPageFeature(false);
        localStorage.removeItem("newLayoutConfirmed");
        notification.destroyAll();
        window.location.reload();
      },
    });
  };

  const handleNewLayoutNotification = () => {
    notification.destroyAll();

    const isAdminPage = computed(() => route.path?.includes("admin"));

    if (
      !isDBPortalType.value &&
      !isPreview.value &&
      fullWidthFeatureCheck.value &&
      !isAdminPage.value
    ) {
      localStorage.removeItem("newLayoutConfirmed");
      offerNewLayout();
    }

    if (localStorage.getItem("newLayoutConfirmed")) {
      confirmNewLayout();
    }
  };

  return { handleNewLayoutNotification };
};
